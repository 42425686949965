import React from 'react';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import BlurbImage from '@components/BlurbImage';
import Text from '@components/Text';
import Browser from '@components/Browser';
import visitLoginImg from '@assets/img/visit-1.jpg';
import visitCheckedInImg from '@assets/img/visit-2.jpg';
import visitProfileImg from '@assets/img/visit-3.jpg';
import visitStats from '@assets/img/visit-stats.gif';
import { Helmet } from 'react-helmet';

const Visit = () => (
    <Transition>
        <Helmet>
            <title>Visit | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/visit" />
        </Helmet>
        <Title>Visit</Title>
        <Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Lead Developer</li>
                        <li>2015</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <Text>Youth Venture is a local San Diego teen center serving East County for over 15 years. They provide attractions and on-site mentoring to at-risk youth in key locations around East County. <br/><br/>I was part of a team of five that joined together to donate a much needed digital check-in system to the organization. The result is Visit, a cloud-based web-app that tracks student visits, organizes volunteer data, and more.</Text>
            )}
        />
        <Browser
            src={visitLoginImg}
        />
        <Blurb
            left={() => (
                <Browser src={visitCheckedInImg} />
            )}
            right={() => (
                <Browser src={visitProfileImg} />
            )}
        />
        <Blurb
            left={() => (
                <Text>Analytics using D3.js</Text>
            )}
            right={() => (
                <>
                    <Text>Visit was born out of YV's desire to make better data-oriented decisions when it came to running the centers. They had tried pen & paper methods of documenting student checkins before, but those methods never stuck, and were obviously hard to maintain.</Text>
                    <br />
                    <Text>Our digital solution allowed for in-depth analytics that Youth Venture has never had the access to before. As a start, I created a heatmap (inspired by Github's commit frequency chart) that showed center admins what days of the week and months of the year were the most active, as well as the exact number of checkins recorded on that day.</Text>
                    <BlurbImage src={visitStats} />
                </>
            )}
            useTopRule={true}
        />
        <Blurb
            left={() => (
                <Text>Usability &amp; Design</Text>
            )}
            right={() => (
                <>
                    <Text>While usable from any device, we crafted the UX of the app to work particularly well on an iPad, which is the main device used by the leaders at each center. Leaders can easily take a photo and input a few key points of data about a student, before checking them into the system, all with the mobility of the iPad.</Text>
                    <br />
                    <Text>For the skin of the interface, I went with a blocky, elementary look as a site-wide design scheme. I felt it conveyed the sense of youthfulness and fun that exists in the centers. Even though the application was built for adult leaders to use, the almost whimsical & comic book-y nature of the colors, shapes, and lines adds a personality that is harmonious with the atmosphere of Youth Venture.</Text>
                </>
            )}
            useTopRule={true}
        />
        <Blurb
            left={() => (
                <Text>Conclusion</Text>
            )}
            right={() => (
                <>
                    <Text>As the lead developer in this project, I was able to oversee the general development of the app's back-end and front-end, as well as have an important voice in key features and design decisions.</Text>
                    <br />
                    <Text>We wrote the front end in Angular, and for the backend used PHP + Postgres, wrapped nicely and neatly in the Laravel Framework. Since its launch in late 2015, Visit has been a huge success and benefit to Youth Venture, and we have already recorded ~15,000 checkins!</Text>
                </>
            )}
            useTopRule={true}
        />
    </Transition>
);

export default Visit;